<template>
  <v-select v-show="getGroups.length > 1"
    :items="getGroups"
    label="Grupo"
    hint="Selecione o Grupo do Usuário"
    v-model="valor"
    required
  ></v-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GroupPicker",
  props:['value'],
  computed: {
    ...mapGetters(["getGroups"]),
    valor:{
      get(){
        return this.value
      },
      set(evento){
        this.$emit('input', evento)
      }
    },
    workGroup: {
      get() {
        return this.$store.state.workGroup;
      },
      set(value) {
        // this.$store.dispatch("setWorkGroup", value);
        this.$emit('group', value)
        this.$emit('input', value)
      },
    },
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
    };
  },
  created() {
    if (!this.getGroups.length) {
      this.$http("grupo").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name}`;
          element.value = element.id;
        });
        this.$store.commit("setGrupos", resp.data);
      });
    }
  },
};
</script>

<style>
</style>